import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { WatchItemInterface } from "../Interfaces";
import { StoreListEnglishToChinese } from "../constants";

function WatchItem(props: WatchItemInterface) {
    const [image, setImage] = useState<string | undefined>(undefined);
    const updateImageToHttps = async (url: string, setter: Dispatch<SetStateAction<string | undefined>>) => {
        const newUrl = await props.getImageCache?.(url);
        setter(newUrl);
    };

    const getColor = (priceChange: number) => {
        if (priceChange === 0) {
            return "text-orange-600";
        } else if (priceChange < 0) {
            return "text-green-600";
        } else {
            return "text-red-600";
        }
    };

    const getPrice = (priceChange: number) => {
        if (priceChange === 0) {
            return "--";
        } else if (priceChange > 0) {
            return `+${priceChange}`;
        } else {
            return priceChange;
        }
    };

    useEffect(() => {
        if (props.bigImage?.includes("http://")) {
            updateImageToHttps(props.bigImage, setImage);
        }
    }, [props.bigImage]);
    return (
        <div className="w-full aspect-w-16 aspect-h-9 object-contain relative">
            <div className="absolute top-0 right-0 left-0 bottom-0 card flex flex-col overflow-hidden">
                <h1 className="px-4 max-w-full bg-gray-600 text-white line-clamp-2">{props.name}</h1>
                <div className="flex flex-1 flex-row">
                    <div className="w-1/3 relative">
                        {image || props.smallImage ? (
                            <img
                                className="h-full w-full object-cover object-center"
                                src={image || props.smallImage.replace("watchsearchmedia", "watchsearch")}
                            />
                        ) : (
                            <div className="h-full w-full flex justify-center items-center text-gray-600 text-xs bg-gray-100">
                                "No Image"
                            </div>
                        )}
                        <div className="absolute left-0 right-0 bottom-0 bg-gray-600 h-5 text-white text-center">
                            {StoreListEnglishToChinese[props.store]}
                        </div>
                    </div>
                    <div className="flex flex-col flex-[2_2_auto]">
                        <div className="relative flex-1">
                            <div className="pt-2 px-1 absolute top-0 left-0 right-0 bottom-0 overflow-y-scroll">
                                <div className="w-full grid watchItemPriceHistoryGrid gap-x-2 font-medium text-xs">
                                    {props.sold === "true" && (
                                        <>
                                            <div className={"whitespace-nowrap"}>
                                                {new Date(props.lastUpdated).toLocaleDateString("en-us", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hourCycle: "h24"
                                                })}
                                            </div>
                                            <div>${props.priceHistory?.[0]?.price || "--"}</div>
                                            <div className={`font-bold text-red-600`}>SOLD</div>
                                        </>
                                    )}
                                    {props.priceHistory?.map((priceRow, index) => (
                                        <Fragment key={`${props.link}${index}`}>
                                            <div className={"whitespace-nowrap"}>
                                                {new Date(priceRow.date).toLocaleDateString("en-us", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hourCycle: "h24"
                                                })}
                                            </div>
                                            <div>${priceRow.price}</div>

                                            <div className={`font-bold ${getColor(priceRow.priceChange)}`}>
                                                {getPrice(priceRow.priceChange)}
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row h-5 text-white">
                            <button
                                className="flex-1 bg-gray-600 text-xs hover:bg-gray-500"
                                onClick={() => {
                                    alert("詳細資料尚未準備好");
                                }}
                            >
                                詳細資料
                            </button>
                            <button
                                className="flex-1 bg-gray-600 text-xs hover:bg-gray-500"
                                onClick={() => {
                                    window.open(props.link);
                                }}
                            >
                                網頁
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WatchItem;
