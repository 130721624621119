import { Fragment, useEffect, useState } from "react";
import FingerprintJS, { GetResult } from "@fingerprintjs/fingerprintjs";
import { Dialog, Transition } from "@headlessui/react";
import {
    BookOpenIcon,
    BriefcaseIcon,
    CogIcon,
    ChartBarIcon,
    CreditCardIcon,
    DuplicateIcon,
    HomeIcon,
    MenuIcon,
    SearchIcon,
    UsersIcon,
    ExternalLinkIcon
} from "@heroicons/react/outline";
import Logo from "./assets/logo.svg";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import {
    Admin,
    Compare,
    Dashboard,
    Egps,
    Error,
    Profile,
    Search,
    Settings,
    Stats,
    Warranty,
    Welcome,
    Yahoo
} from "./pages";
import { StoreList, TitleToChinese } from "./constants";
import {
    MapInterface,
    WatchItemInterface,
    WatchItemsListStoreMapInterface,
    YahooWatchItemsInterface,
    UserDataInterface,
    NavigationInterface,
    RouteInterface,
    GeolocationInterface,
    EgpsWatchItemInterface,
    UserIdMap
} from "./Interfaces";
import { WatchListContext } from "./context/WatchList";
import { UtilStateContext } from "./context/UtilState";
import { AdminContext } from "./context/Admin";
import { db } from "./db";
import { YahooWatchListContext } from "./context/YahooList";
import useAPI from "./hooks/useAPI";
import { DateValueType } from "react-tailwindcss-datepicker";

const fpPromise = FingerprintJS.load();

const navigation: Array<NavigationInterface> = [
    { name: "控制板", href: "/dashboard", icon: HomeIcon, current: true, userDataName: "dashboardAccess" },
    { name: "搜尋", href: "/search", icon: SearchIcon, current: false, userDataName: "searchAccess" },
    { name: "相比", href: "/compare", icon: DuplicateIcon, current: false, userDataName: "compareAccess" },
    { name: "Yahoo", href: "/yahoo", icon: CreditCardIcon, current: false, userDataName: "yahooAccess" },
    { name: "保修單", href: "/warranty", icon: BookOpenIcon, current: false, userDataName: "warrantyAccess" },
    { name: "EGPS", href: "/egps", icon: BriefcaseIcon, current: false, userDataName: "egpsAccess" },
    { name: "數據", href: "/stats", icon: ChartBarIcon, current: false, userDataName: "statsAccess" },
    { name: "設置", href: "/settings", icon: CogIcon, current: false },
    { name: "管理", href: "/admin", icon: UsersIcon, current: false, userDataName: "admin" }
];

const accessibleRoutes: Array<RouteInterface> = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        keyCheck: "dashboardAccess"
    },
    {
        path: "/search",
        element: <Search />,
        keyCheck: "searchAccess"
    },
    {
        path: "/warranty",
        element: <Warranty />,
        keyCheck: "warrantyAccess"
    },
    {
        path: "/egps",
        element: <Egps />,
        keyCheck: "egpsAccess"
    },
    {
        path: "/yahoo",
        element: <Yahoo />,
        keyCheck: "yahooAccess"
    },
    {
        path: "/compare",
        element: <Compare />,
        keyCheck: "compareAccess"
    },
    {
        path: "/stats",
        element: <Stats />,
        keyCheck: "statsAccess"
    },
    {
        path: "/admin",
        element: <Admin />,
        keyCheck: "admin"
    }
];

const navigationToNameMap: MapInterface = {
    "/": "歡迎！",
    "/dashboard": "控制板",
    "/search": "搜尋",
    "/compare": "相比",
    "/yahoo": "Yahoo",
    "/warranty": "保修單",
    "/egps": "EGPS",
    "/stats": "數據",
    "/settings": "設置",
    "/admin": "管理"
};

function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(" ");
}

function App() {
    // Utils
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState("");
    const [authentication, setAuthentication] = useState<string | null>(null);
    const API = useAPI(authentication || "");
    const [userData, setUserData] = useState<UserDataInterface | null>(null);
    const [userIP, setUserIP] = useState<string>("");
    const [userFingerprint, setUserFingerPrint] = useState<GetResult | null>(null);
    const [userLocation, setUserLocation] = useState<GeolocationInterface | null>(null);
    const [usersActivity, setUsersActivity] = useState([]);
    const [employees, setEmployees] = useState<Array<UserIdMap>>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [deviceHashMap, setDeviceHashMap] = useState<MapInterface>({});

    // State
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedSearchStore, setSelectedSearchStore] = useState("All");
    const [updatingDB, setUpdatingDB] = useState(false);
    const [purgingKeys, setPurgingKeys] = useState(false);

    // Data
    const [allWatchesList, setAllWatchesList] = useState<Array<WatchItemInterface>>([]);
    const [watchList, setWatchList] = useState<WatchItemsListStoreMapInterface>({});
    const [yahooEvergrowList, setYahooEvergrowList] = useState<Array<YahooWatchItemsInterface>>([]);
    const [yahooEverlongList, setYahooEverlongList] = useState<Array<YahooWatchItemsInterface>>([]);
    const [yahooEvergrowLastUpdated, setYahooEvergrowListLastUpdated] = useState<number | null>(null);
    const [yahooEverlongLastUpdated, setYahooEverlongListLastUpdated] = useState<number | null>(null);
    const [yahooEvergrowUpdating, setYahooEvergrowUpdating] = useState(false);
    const [yahooEverlongUpdating, setYahooEverlongUpdating] = useState(false);
    const [egpsWatches, setEgpsWatches] = useState<Array<EgpsWatchItemInterface>>([]);

    // Functions
    const updateWatchLists = async () => {
        setUpdatingDB(true);
        //Get from Cache
        const updatedFromCache = await updateFromCache();
        if (updatedFromCache) {
            setUpdatingDB(false);
        }

        //Get from Server
        await updateFromServer();
        setUpdatingDB(false);
    };

    const updateFromCache = async () => {
        const data = await db.watchList.toArray();
        if (data.length > 0) {
            const newWatchList: WatchItemsListStoreMapInterface = {};
            data.forEach(({ store, watches }) => {
                newWatchList[store] = watches;
            });
            setWatchList((oldWatchList) => ({ ...oldWatchList, ...newWatchList }));
            return true;
        }
        return false;
    };

    const updateFromServer = async () => {
        const newWatchList: WatchItemsListStoreMapInterface = {};
        const watchListPromiseList = await Promise.all(
            StoreList.filter((store) => store !== "EGPS").map(async (store) => {
                const storeWatchList: Array<WatchItemInterface> = await API.getWatchLists(store);

                storeWatchList.sort((a, b) => {
                    let aTime, bTime;
                    if (a.sold === "true" || !a.priceHistory?.[0].date) {
                        aTime = a.lastUpdated;
                    } else {
                        aTime = a.priceHistory[0].date;
                    }
                    if (b.sold === "true" || !b.priceHistory?.[0].date) {
                        bTime = b.lastUpdated;
                    } else {
                        bTime = b.priceHistory[0].date;
                    }
                    const timeDiff = aTime - bTime;

                    if (timeDiff > 0) {
                        return -1;
                    } else if (timeDiff < 0) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                await db.watchList.put({
                    store: store,
                    watches: storeWatchList
                });
                return {
                    key: store,
                    value: storeWatchList
                };
            })
        );
        watchListPromiseList.forEach((store) => {
            newWatchList[store.key] = store.value;
        });
        setWatchList((oldWatchList) => ({ ...oldWatchList, ...newWatchList }));
    };

    const updateYahooLists = async () => {
        const [evergrowSuccess, everLongSuccess] = await Promise.all([
            updateYahooListFromCache("evergrow"),
            updateYahooListFromCache("everlong")
        ]);
        if (!evergrowSuccess) {
            updateYahooListFromServer("evergrow");
        }
        if (!everLongSuccess) {
            updateYahooListFromServer("everlong");
        }
    };

    const updateYahooListFromCache = async (store: string) => {
        const data = await db.yahooList.toArray();
        const yahooList = data.filter((yahooListMap) => yahooListMap.store === store)?.[0];
        if (!yahooList) {
            return false;
        }
        if (store === "evergrow") {
            setYahooEvergrowList(yahooList.watches || []);
            try {
                const lastUpdated = localStorage.getItem("yegts");
                if (lastUpdated) {
                    setYahooEvergrowListLastUpdated(parseInt(lastUpdated));
                }
            } catch {
                setYahooEvergrowListLastUpdated(null);
            }
        } else {
            setYahooEverlongList(yahooList.watches || []);
            try {
                const lastUpdated = localStorage.getItem("yelts");
                if (lastUpdated) {
                    setYahooEverlongListLastUpdated(parseInt(lastUpdated));
                }
            } catch {
                setYahooEverlongListLastUpdated(null);
            }
        }
        return true;
    };

    const updateYahooListFromServer = async (store: string) => {
        if (store === "evergrow") {
            setYahooEvergrowUpdating(true);
            setYahooEvergrowListLastUpdated(null);
        } else {
            setYahooEverlongUpdating(true);
            setYahooEverlongListLastUpdated(null);
        }
        const yahooList = await API.getYahooLists(store);

        const currentTime = Date.now();
        if (store === "evergrow") {
            setYahooEvergrowList(yahooList);
            setYahooEvergrowUpdating(false);
            setYahooEvergrowListLastUpdated(Date.now());
            localStorage.setItem("yegts", currentTime.toString());
        } else {
            setYahooEverlongList(yahooList);
            setYahooEverlongUpdating(false);
            setYahooEverlongListLastUpdated(Date.now());
            localStorage.setItem("yelts", currentTime.toString());
        }
        await db.yahooList.put({
            store: store,
            watches: yahooList
        });
    };

    const updateEgpsWatchLists = async () => {
        //Get from Cache
        await updateEgpsWatchesFromCache();
        //Get from Server
        await updateEgpsWatchesFromServer();
    };
    const updateEgpsWatchesFromCache = async () => {
        const data = await db.egpsWatchItems.toArray();
        if (data.length > 0) {
            setEgpsWatches(data);
        }
    };

    const updateEgpsWatchesFromServer = async () => {
        const egpsWatchList: Array<EgpsWatchItemInterface> = await API.getEgpsWatchList();
        egpsWatchList.sort((a, b) => {
            let aTime, bTime;
            if (a.sold === "true") {
                aTime = a.lastUpdated;
            } else {
                aTime = a.priceHistory[0].date;
            }
            if (b.sold === "true") {
                bTime = b.lastUpdated;
            } else {
                bTime = b.priceHistory[0].date;
            }
            const timeDiff = aTime - bTime;

            if (timeDiff > 0) {
                return -1;
            } else if (timeDiff < 0) {
                return 1;
            } else {
                return 0;
            }
        });
        await db.egpsWatchItems.bulkPut(egpsWatchList);
        setEgpsWatches(egpsWatchList);
    };

    const updateUserData = async () => {
        const user = await API.getUser();
        if (!user) {
            alert("您的帳戶有問題");
            logout();
        }
        if (user.disabled) {
            alert("您的帳戶已被鎖定");
            logout();
        }
        setUserData(user);
    };

    const updateActivityList = async (user?: string, dateRange?: DateValueType) => {
        const activity = await API.getUsersActivity(user, dateRange);
        setUsersActivity(activity);
    };

    const updateUtilData = async () => {
        const utilData = await API.getUtilData();
        setEmployees(utilData?.users || []);
    };

    const checkAuthentication = async () => {
        // Check url
        if (window?.location?.href.includes("#id_token")) {
            const token = window.location.href.split("#id_token=")[1].split("&")[0];
            if (token) {
                await checkTokenValid(token);
                localStorage.setItem("atk", token);
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            }
        } else {
            const authToken = localStorage.getItem("atk");
            if (authToken) {
                await checkTokenValid(authToken);
                setAuthentication(authToken);
                return;
            }
            // No token case
            redirectLogin();
        }
    };

    const checkTokenValid = async (token: string) => {
        const valid = await API.checkToken(token);
        if (!valid) {
            redirectLogin();
            return;
        }
    };

    const checkIP = async () => {
        const res = await fetch("https://api.ipify.org?format=json");
        if (res.status !== 200) {
            setUserIP("NO IP FOUND");
            return;
        }
        const data = await res.json();
        if (data?.ip) {
            setUserIP(data.ip);
        }
    };

    const checkFingerPrint = async () => {
        const fp = await fpPromise;
        const result = await fp.get();
        setUserFingerPrint(result);
    };

    const checkGeolocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            });
        }
    };

    const postMetadata = (
        userIP: string,
        userFingerprint: GetResult,
        userLocation: GeolocationInterface | null,
        pathname: string
    ) => {
        API.postMetadata(userIP, userFingerprint, userLocation, pathname);
    };

    const formatEgpsWatches = (egpsWatches: Array<EgpsWatchItemInterface>) => {
        return egpsWatches.map((watchItem: EgpsWatchItemInterface) => ({
            lastUpdated: watchItem.lastUpdated,
            raw: watchItem.nameLong,
            brand: watchItem.brand,
            name: watchItem.nameLong,
            originalImageLinks: [],
            smallImage: watchItem.smallImage,
            firstAdded: 0,
            sold: watchItem.sold.toLowerCase(),
            priceHistory: watchItem.priceHistory,
            watchID: watchItem.privateID,
            link: `http://www.egps.com.tw/products_open.asp?ID=${watchItem.urlID}`,
            bigImage: watchItem.bigImage,
            productID: watchItem.publicID,
            store: "EGPS",
            urlID: watchItem.urlID
        }));
    };

    const redirectLogin = () => {
        window.open(
            `https://watchsearch.auth.ap-northeast-1.amazoncognito.com/login?client_id=4vtuiec1ouo1919lm6m7sqtqrf&response_type=token&scope=email+openid&redirect_uri=${window.location.origin}/`,
            "_self"
        );
    };

    const logout = () => {
        localStorage.removeItem("atk");
        setAuthentication(null);
        setPurgingKeys(true);
    };

    // UseEffects
    useEffect(() => {
        if (purgingKeys && !localStorage.getItem("atk")) {
            redirectLogin();
        }
    }, [purgingKeys, localStorage.getItem("atk")]);

    useEffect(() => {
        setCurrentLocation("/" + location.pathname.split("/")[1]);
    }, [location]);

    useEffect(() => {
        if (authentication) {
            updateUserData();
        }
    }, [authentication]);

    useEffect(() => {
        if (userData) {
            updateWatchLists();
            updateYahooLists();
            updateEgpsWatchLists();
            if (userData.admin) {
                updateActivityList();
                updateUtilData();
            }
        }
    }, [userData]);

    useEffect(() => {
        checkAuthentication();
        checkIP();
        checkGeolocation();
        setTimeout(checkFingerPrint, 3000);
    }, []);

    useEffect(() => {
        if (
            !updatingDB &&
            StoreList.filter((store) => store !== "EGPS").every((storeName) => watchList[storeName]?.length > 0)
        ) {
            let newAllWatchesList: Array<WatchItemInterface> = [];
            StoreList.forEach((storeName) =>
                watchList[storeName]?.forEach((watchItem) => newAllWatchesList.push(watchItem))
            );
            newAllWatchesList = [...newAllWatchesList];
            newAllWatchesList.sort((a, b) => {
                let aTime, bTime;
                if (a.sold === "true") {
                    aTime = a.lastUpdated;
                } else {
                    aTime = a.priceHistory?.[0]?.date || 0;
                }
                if (b.sold === "true") {
                    bTime = b.lastUpdated;
                } else {
                    bTime = b.priceHistory?.[0]?.date || 0;
                }
                const timeDiff = aTime - bTime;

                if (timeDiff > 0) {
                    return -1;
                } else if (timeDiff < 0) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setAllWatchesList(newAllWatchesList);
        }
    }, [updatingDB, watchList, egpsWatches]);

    useEffect(() => {
        if (authentication && userIP && userFingerprint) {
            postMetadata(
                userIP,
                userFingerprint,
                userLocation,
                `${location?.pathname}${location?.search || ""}`.substring(1)
            );
        }
    }, [userFingerprint, userIP, userLocation, authentication, location]);

    useEffect(() => {
        if (watchList && egpsWatches?.length > 0) {
            setWatchList((oldWatchList) => ({
                ...oldWatchList,
                EGPS: formatEgpsWatches(egpsWatches)
            }));
        }
    }, [egpsWatches]);

    return (
        <div className="h-full flex">
            <MobileSideBar {...{ currentLocation, sidebarOpen, setSidebarOpen, logout: logout, userData: userData }} />

            <DesktopSideBar
                {...{
                    currentLocation,
                    logout: logout,
                    userData: userData,
                    userIP,
                    userFingerprintHash: userFingerprint?.visitorId || ""
                }}
            />
            <WatchListContext.Provider
                value={{
                    allWatchesList,
                    watchList,
                    egpsWatches,
                    getImageCache: API.getImageCache
                }}
            >
                <YahooWatchListContext.Provider
                    value={{
                        yahooEvergrowList,
                        yahooEverlongList,
                        refreshEvergrow: () => updateYahooListFromServer("evergrow"),
                        refreshEverlong: () => updateYahooListFromServer("everlong"),
                        yahooEvergrowLastUpdated,
                        yahooEverlongLastUpdated,
                        yahooEvergrowUpdating,
                        yahooEverlongUpdating,
                        getImageCache: API.getImageCache
                    }}
                >
                    <UtilStateContext.Provider value={{ selectedSearchStore, setSelectedSearchStore, userData }}>
                        <AdminContext.Provider value={{ usersActivity, deviceHashMap, updateActivityList, employees }}>
                            <div className="md:pl-64 flex flex-col flex-1">
                                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100 flex">
                                    <button
                                        type="button"
                                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        onClick={() => setSidebarOpen(true)}
                                    >
                                        <span className="sr-only">Open sidebar</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                    <h1 className="text-2xl font-semibold text-gray-900 pr-8 md:mr-2 md:pb-4 flex items-center justify-center md:hidden mx-auto text-center">
                                        {navigationToNameMap[currentLocation]}
                                    </h1>
                                </div>
                                <main className="flex-1">
                                    <div className="h-full">
                                        <Routes>
                                            <Route path="/" element={<Welcome />}></Route>
                                            <Route path="/settings" element={<Settings />}></Route>
                                            <Route path="/profile" element={<Profile />}></Route>
                                            {userData &&
                                                accessibleRoutes.map(
                                                    (route) =>
                                                        userData[route.keyCheck] && (
                                                            <Route
                                                                key={route.path}
                                                                path={route.path}
                                                                element={route.element}
                                                            ></Route>
                                                        )
                                                )}
                                            <Route path="/*" element={<Error />}></Route>
                                        </Routes>
                                    </div>
                                </main>
                            </div>
                        </AdminContext.Provider>
                    </UtilStateContext.Provider>
                </YahooWatchListContext.Provider>
            </WatchListContext.Provider>
        </div>
    );
}

const DesktopSideBar = ({
    currentLocation,
    logout,
    userData
}: {
    currentLocation: string;
    logout: () => void;
    userData: UserDataInterface | null;
    userIP: string;
    userFingerprintHash: string;
}) => {
    return (
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-4">
                        <img className="h-8 w-auto" src={Logo} alt="WatchSearch" />
                        <h1 className="text-2xl font-semibold text-white ml-2">WatchSearch</h1>
                    </div>
                    <nav className="mt-5 flex-1 px-2 space-y-1">
                        {navigation.map((item) => {
                            if ((item.userDataName && userData?.[item.userDataName]) || !item.userDataName) {
                                return (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.href === currentLocation
                                                ? "bg-gray-900 text-white"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.href === currentLocation
                                                    ? "text-gray-300"
                                                    : "text-gray-400 group-hover:text-gray-300",
                                                "mr-3 flex-shrink-0 h-6 w-6"
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                );
                            }
                        })}
                        <a
                            href={"https://egps.vercel.app"}
                            target="_blank"
                            className={classNames(
                                "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}
                        >
                            <ExternalLinkIcon
                                className={classNames(
                                    "text-gray-400 group-hover:text-gray-300",
                                    "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                            />
                            測試新網站
                        </a>
                    </nav>
                </div>
                <div className="mx-4 truncate">
                    <div className="text-white text-xs">
                        v1.0.1
                        {/* {userIP} {userFingerprintHash} */}
                    </div>
                </div>
                {userData && (
                    <div className="flex-shrink-0 flex bg-gray-700 p-4">
                        <Link to="/profile" className="flex-shrink-0 w-full group block">
                            <div className="flex items-center">
                                <div>
                                    <svg
                                        className="inline-block h-9 w-9 rounded-full"
                                        version="1.0"
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    >
                                        <g>
                                            <path d="m-13.772 5.9051c0.18117 0.85692 0.2823 1.5051 0.13532 2.362-1.1459 0.95067-4.0764 1.3779-4.0721 2.362l-0.39367 2.5589c0 0.97839 2.7319 1.7715 6.1019 1.7715s6.1019-0.79314 6.1019-1.7715l-0.39367-2.5589c-0.0047-0.84233-2.9525-1.3779-4.0844-2.362-0.09668-0.79535-0.01972-1.5667 0.14763-2.362h-3.5431z" />
                                            <path
                                                transform="matrix(.34209 0 0 .34209 -8.6387 -12.265)"
                                                d="m-9.75 73.094c-3.7664 0.12107-7.4681 1.3864-11.406 3.25a1.2533 1.2533 0 0 0-0.6875 1.4375l0.625 2.5312a1.2533 1.2533 0 0 0 0.78125 0.84375c0.16176 0.06256 0.27543 0.18379 0.71875 0.3125 2.3353 0.67799 5.908 1.1562 9.9062 1.1562 3.9983 0 7.571-0.47826 9.9062-1.1562 0.44332-0.12871 0.55699-0.24994 0.71875-0.3125a1.2533 1.2533 0 0 0 0.78125-0.8125l0.65625-2.3125a1.2533 1.2533 0 0 0-0.53125-1.375c-3.9239-2.6139-7.7023-3.6836-11.469-3.5625z"
                                            />
                                            <rect
                                                transform="matrix(.92052 -.39069 .39069 .92052 0 0)"
                                                x="-15.57"
                                                y="-2.7547"
                                                width="1.0936"
                                                height="2.1872"
                                                rx="1.181"
                                                ry="1.181"
                                            />
                                            <rect
                                                transform="matrix(-.92052 -.39069 -.39069 .92052 0 0)"
                                                x="6.5567"
                                                y="6.6362"
                                                width="1.0936"
                                                height="2.1872"
                                                rx="1.181"
                                                ry="1.181"
                                            />
                                            <path d="m-12 0c-1.6306 0-2.9525 1.2338-2.9525 2.7557 0.01278 0.56324 0.06085 1.2323 0.39367 2.7557 0.19684 0.59051 1.5589 2.1652 1.5747 2.362 0.38173 0.19684 1.7715 0.19684 2.1652 0 0-0.19684 1.181-1.7715 1.3779-2.362 0.37453-1.5832 0.37358-2.1652 0.39367-2.7557 0-1.5219-1.3219-2.7557-2.9525-2.7557z" />
                                            <path
                                                d="m38 0c-1.6306 0-2.9375 1.2281-2.9375 2.75 0.0037 0.16207 0.01579 0.39632 0.03125 0.59375-0.27885 0.11835-0.2992 0.66105-0.0625 1.2188 0.09386 0.22116 0.21341 0.39097 0.34375 0.53125 0.03167 0.15674 0.02336 0.2271 0.0625 0.40625 0.19684 0.59051 1.5779 2.1782 1.5938 2.375 0.38173 0.19684 1.7626 0.19684 2.1562 0 0-0.19684 1.1782-1.7845 1.375-2.375 0.04372-0.18481 0.05963-0.30962 0.09375-0.46875 0.10858-0.13153 0.20126-0.28029 0.28125-0.46875 0.22486-0.52981 0.21033-1.0356-0.03125-1.1875 0.014243-0.21347 0.024977-0.44061 0.03125-0.625 0-1.5219-1.3069-2.75-2.9375-2.75zm-1.7812 8.4062c-1.2335 0.87068-3.9417 1.275-3.9375 2.2188l-0.375 2.5625c0 0.51901 0.775 0.98849 2 1.3125l0.1875 0.71875a0.42875 0.42875 0 0 0 0.28125 0.28125c0.05534 0.0214 0.09834 0.04972 0.25 0.09375 0.79889 0.23194 2.0072 0.40625 3.375 0.40625s2.6074-0.17431 3.4062-0.40625c0.15166-0.04403 0.16341-0.07235 0.21875-0.09375a0.42875 0.42875 0 0 0 0.28125-0.28125l0.21875-0.71875c1.2086-0.32369 1.9688-0.79747 1.9688-1.3125l-0.375-2.5625c-0.004331-0.77614-2.5012-1.2813-3.7812-2.125a0.97584 0.97584 0 0 1-0.3125 0.25c-0.60499 0.3025-1.0167 0.25-1.5312 0.25-0.25727 0-0.5058 4.922e-4 -0.75-0.03125-0.2442-0.031742-0.44159-0.059724-0.75-0.21875a0.97584 0.97584 0 0 1-0.375-0.34375z"
                                                fill="#bebebe"
                                            />
                                            <path
                                                d="m8 0.77838c-2.2565 0-4.0649 1.6994-4.0649 3.8054 0.00509 0.22426 0.021845 0.54843 0.043244 0.82162-0.38587 0.16377-0.41403 0.91475-0.086487 1.6865 0.12989 0.30603 0.29531 0.54101 0.47568 0.73514 0.043823 0.21689 0.032325 0.31426 0.086486 0.56216 0.15114 0.45341 0.74701 1.342 1.2973 2.0757 0.05396 0.56342 0.10994 1.132 0 1.773-1.5856 1.3155-5.6709 1.8813-5.6649 3.2432l-0.086486 0.51892h16l-0.08649-0.51892c-0.0065-1.1656-4.0987-1.8813-5.6649-3.2432-0.06337-0.52134-0.07545-1.0433-0.04324-1.5568 0.50143-0.77381 1.1722-1.7869 1.3405-2.2919 0.0605-0.25574 0.08252-0.42845 0.12973-0.64865 0.15026-0.18201 0.2785-0.38786 0.38919-0.64865 0.31116-0.73315 0.29105-1.433-0.04324-1.6432 0.01971-0.2954 0.03456-0.60971 0.04324-0.86486 0-2.106-1.8084-3.8054-4.0649-3.8054z"
                                                fill="#bebebe"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-white">{userData?.name}</p>
                                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                                        {TitleToChinese[userData?.title] || userData?.title || ""}
                                    </p>
                                </div>
                                <button onClick={logout} className="flex-1 text-center text-white font-bold">
                                    登出
                                </button>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

const MobileSideBar = ({
    currentLocation,
    sidebarOpen,
    setSidebarOpen,
    logout,
    userData
}: {
    currentLocation: string;
    sidebarOpen: boolean;
    setSidebarOpen: (sidebarOpen: boolean) => void;
    logout: () => void;
    userData: UserDataInterface | null;
}) => {
    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 flex z-40">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img className="h-8 w-auto" src={Logo} alt="WatchSearch" />
                                    <h1 className="text-2xl font-semibold text-white ml-2">WatchSearch</h1>
                                </div>
                                <nav className="mt-5 px-2 space-y-1">
                                    {navigation.map((item) => {
                                        if (
                                            (item.userDataName && userData?.[item.userDataName]) ||
                                            !item.userDataName
                                        ) {
                                            return (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.href === currentLocation
                                                            ? "bg-gray-900 text-white"
                                                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.href === currentLocation
                                                                ? "text-gray-300"
                                                                : "text-gray-400 group-hover:text-gray-300",
                                                            "mr-3 flex-shrink-0 h-6 w-6"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            );
                                        }
                                    })}
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex bg-gray-700 p-4">
                                <Link to="/profile" className="flex-shrink-0 w-full group block">
                                    <div className="flex items-center">
                                        <div>
                                            <svg
                                                className="inline-block h-9 w-9 rounded-full"
                                                version="1.0"
                                                viewBox="0 0 16 16"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g>
                                                    <path d="m-13.772 5.9051c0.18117 0.85692 0.2823 1.5051 0.13532 2.362-1.1459 0.95067-4.0764 1.3779-4.0721 2.362l-0.39367 2.5589c0 0.97839 2.7319 1.7715 6.1019 1.7715s6.1019-0.79314 6.1019-1.7715l-0.39367-2.5589c-0.0047-0.84233-2.9525-1.3779-4.0844-2.362-0.09668-0.79535-0.01972-1.5667 0.14763-2.362h-3.5431z" />
                                                    <path
                                                        transform="matrix(.34209 0 0 .34209 -8.6387 -12.265)"
                                                        d="m-9.75 73.094c-3.7664 0.12107-7.4681 1.3864-11.406 3.25a1.2533 1.2533 0 0 0-0.6875 1.4375l0.625 2.5312a1.2533 1.2533 0 0 0 0.78125 0.84375c0.16176 0.06256 0.27543 0.18379 0.71875 0.3125 2.3353 0.67799 5.908 1.1562 9.9062 1.1562 3.9983 0 7.571-0.47826 9.9062-1.1562 0.44332-0.12871 0.55699-0.24994 0.71875-0.3125a1.2533 1.2533 0 0 0 0.78125-0.8125l0.65625-2.3125a1.2533 1.2533 0 0 0-0.53125-1.375c-3.9239-2.6139-7.7023-3.6836-11.469-3.5625z"
                                                    />
                                                    <rect
                                                        transform="matrix(.92052 -.39069 .39069 .92052 0 0)"
                                                        x="-15.57"
                                                        y="-2.7547"
                                                        width="1.0936"
                                                        height="2.1872"
                                                        rx="1.181"
                                                        ry="1.181"
                                                    />
                                                    <rect
                                                        transform="matrix(-.92052 -.39069 -.39069 .92052 0 0)"
                                                        x="6.5567"
                                                        y="6.6362"
                                                        width="1.0936"
                                                        height="2.1872"
                                                        rx="1.181"
                                                        ry="1.181"
                                                    />
                                                    <path d="m-12 0c-1.6306 0-2.9525 1.2338-2.9525 2.7557 0.01278 0.56324 0.06085 1.2323 0.39367 2.7557 0.19684 0.59051 1.5589 2.1652 1.5747 2.362 0.38173 0.19684 1.7715 0.19684 2.1652 0 0-0.19684 1.181-1.7715 1.3779-2.362 0.37453-1.5832 0.37358-2.1652 0.39367-2.7557 0-1.5219-1.3219-2.7557-2.9525-2.7557z" />
                                                    <path
                                                        d="m38 0c-1.6306 0-2.9375 1.2281-2.9375 2.75 0.0037 0.16207 0.01579 0.39632 0.03125 0.59375-0.27885 0.11835-0.2992 0.66105-0.0625 1.2188 0.09386 0.22116 0.21341 0.39097 0.34375 0.53125 0.03167 0.15674 0.02336 0.2271 0.0625 0.40625 0.19684 0.59051 1.5779 2.1782 1.5938 2.375 0.38173 0.19684 1.7626 0.19684 2.1562 0 0-0.19684 1.1782-1.7845 1.375-2.375 0.04372-0.18481 0.05963-0.30962 0.09375-0.46875 0.10858-0.13153 0.20126-0.28029 0.28125-0.46875 0.22486-0.52981 0.21033-1.0356-0.03125-1.1875 0.014243-0.21347 0.024977-0.44061 0.03125-0.625 0-1.5219-1.3069-2.75-2.9375-2.75zm-1.7812 8.4062c-1.2335 0.87068-3.9417 1.275-3.9375 2.2188l-0.375 2.5625c0 0.51901 0.775 0.98849 2 1.3125l0.1875 0.71875a0.42875 0.42875 0 0 0 0.28125 0.28125c0.05534 0.0214 0.09834 0.04972 0.25 0.09375 0.79889 0.23194 2.0072 0.40625 3.375 0.40625s2.6074-0.17431 3.4062-0.40625c0.15166-0.04403 0.16341-0.07235 0.21875-0.09375a0.42875 0.42875 0 0 0 0.28125-0.28125l0.21875-0.71875c1.2086-0.32369 1.9688-0.79747 1.9688-1.3125l-0.375-2.5625c-0.004331-0.77614-2.5012-1.2813-3.7812-2.125a0.97584 0.97584 0 0 1-0.3125 0.25c-0.60499 0.3025-1.0167 0.25-1.5312 0.25-0.25727 0-0.5058 4.922e-4 -0.75-0.03125-0.2442-0.031742-0.44159-0.059724-0.75-0.21875a0.97584 0.97584 0 0 1-0.375-0.34375z"
                                                        fill="#bebebe"
                                                    />
                                                    <path
                                                        d="m8 0.77838c-2.2565 0-4.0649 1.6994-4.0649 3.8054 0.00509 0.22426 0.021845 0.54843 0.043244 0.82162-0.38587 0.16377-0.41403 0.91475-0.086487 1.6865 0.12989 0.30603 0.29531 0.54101 0.47568 0.73514 0.043823 0.21689 0.032325 0.31426 0.086486 0.56216 0.15114 0.45341 0.74701 1.342 1.2973 2.0757 0.05396 0.56342 0.10994 1.132 0 1.773-1.5856 1.3155-5.6709 1.8813-5.6649 3.2432l-0.086486 0.51892h16l-0.08649-0.51892c-0.0065-1.1656-4.0987-1.8813-5.6649-3.2432-0.06337-0.52134-0.07545-1.0433-0.04324-1.5568 0.50143-0.77381 1.1722-1.7869 1.3405-2.2919 0.0605-0.25574 0.08252-0.42845 0.12973-0.64865 0.15026-0.18201 0.2785-0.38786 0.38919-0.64865 0.31116-0.73315 0.29105-1.433-0.04324-1.6432 0.01971-0.2954 0.03456-0.60971 0.04324-0.86486 0-2.106-1.8084-3.8054-4.0649-3.8054z"
                                                        fill="#bebebe"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-base font-medium text-white">{userData?.name}</p>
                                            <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                                                {TitleToChinese[userData?.title || userData?.title || ""]}
                                            </p>
                                        </div>

                                        <button onClick={logout} className="flex-1 text-center text-white font-bold">
                                            登出
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default App;
