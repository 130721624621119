import { useEffect } from "react";
import { WatchItemYahoo } from "../components";
import { useYahooWatchListContext } from "../context/YahooList";
import { YahooWatchItemsInterface, YahooWatchCompareListInterface } from "../Interfaces";
import { useState } from "react";

function Yahoo() {
    const {
        yahooEvergrowList,
        yahooEverlongList,
        refreshEvergrow,
        refreshEverlong,
        yahooEvergrowLastUpdated,
        yahooEverlongLastUpdated,
        yahooEvergrowUpdating,
        yahooEverlongUpdating,
        getImageCache
    } = useYahooWatchListContext();
    const [yahooLists, setYahooList] = useState<Array<YahooWatchCompareListInterface>>([]);
    const [currentlyShowing, setCurrentlyShowing] = useState<string>("永生");

    const formatDate = (timeStamp: number | null) => {
        if (timeStamp) {
            const date = new Date(timeStamp);
            return date.toLocaleString("zh-TW");
        }
        return "--";
    };
    useEffect(() => {
        setYahooList([
            {
                name: "永生",
                list: yahooEvergrowList,
                refresh: refreshEvergrow,
                numPrice: yahooEvergrowList.filter((watchItem: YahooWatchItemsInterface) => watchItem.type === "price")
                    .length,
                numRepeat: yahooEvergrowList.filter(
                    (watchItem: YahooWatchItemsInterface) => watchItem.type === "repeat"
                ).length,
                numImage: yahooEvergrowList.filter((watchItem: YahooWatchItemsInterface) => watchItem.type === "image")
                    .length,
                numMissing: yahooEvergrowList.filter(
                    (watchItem: YahooWatchItemsInterface) => watchItem.type === "missing"
                ).length,
                lastUpdated: formatDate(yahooEvergrowLastUpdated),
                updating: yahooEvergrowUpdating,
                getImageCache
            },
            {
                name: "永久",
                list: yahooEverlongList,
                refresh: refreshEverlong,
                numPrice: yahooEverlongList.filter((watchItem: YahooWatchItemsInterface) => watchItem.type === "price")
                    .length,
                numRepeat: yahooEverlongList.filter(
                    (watchItem: YahooWatchItemsInterface) => watchItem.type === "repeat"
                ).length,
                numImage: yahooEverlongList.filter((watchItem: YahooWatchItemsInterface) => watchItem.type === "image")
                    .length,
                numMissing: yahooEverlongList.filter(
                    (watchItem: YahooWatchItemsInterface) => watchItem.type === "missing"
                ).length,
                lastUpdated: formatDate(yahooEverlongLastUpdated),
                updating: yahooEverlongUpdating,
                getImageCache
            }
        ]);
    }, [yahooEvergrowList, yahooEverlongList, refreshEvergrow, refreshEverlong]);
    return (
        <div className="h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8 sm:p-6 ">
            <h1 className="text-2xl font-semibold text-gray-900 mb-4 hidden md:block">Yahoo</h1>
            <div className="relative z-0 inline-flex shadow-sm rounded-md mb-2 lg:hidden mx-auto">
                <button
                    onClick={() => setCurrentlyShowing("永生")}
                    // eslint-disable-next-line prettier/prettier
                    className={`${currentlyShowing === "永生" ? "text-gray-50 bg-gray-700 hover:bg-gray-600" : "bg-white text-gray-700 hover:bg-gray-100"} relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-bold focus:z-10 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:border-slate-500`}
                >
                    永生
                </button>
                <button
                    onClick={() => setCurrentlyShowing("永久")}
                    // eslint-disable-next-line prettier/prettier
                    className={`${currentlyShowing === "永久" ? "text-gray-50 bg-gray-700 hover:bg-gray-600" : "bg-white text-gray-700 hover:bg-gray-100"} -ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-bold focus:z-10 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:border-slate-500`}
                >
                    永久
                </button>
            </div>
            <div className="flex flex-1 flex-col lg:flex-row gap-4">
                {yahooLists.map((yahooList) => (
                    <div
                        // eslint-disable-next-line prettier/prettier
                        className={`flex flex-col flex-1 gap-4 pb-4 ${currentlyShowing === yahooList.name ? "hidden" : "flex"} lg:flex`}
                        key={yahooList.name}
                    >
                        <div className="card h-24 flex flex-col p-2">
                            <div className="flex flex-row items-end justify-between">
                                <h1 className="text-lg font-semibold text-gray-900">{yahooList.name}</h1>
                                {yahooList.updating ? (
                                    <div
                                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-gray-600 bg-slate-200 cursor-not-allowed"
                                        onClick={yahooList.refresh}
                                    >
                                        更新中...
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        onClick={yahooList.refresh}
                                    >
                                        更新
                                    </button>
                                )}
                            </div>
                            <span className="text-sm text-slate-600">
                                最近更新時間: <span className="font-bold">{yahooList.lastUpdated}</span>
                            </span>
                            <div className="flex flex-row flex-1 justify-between text-sm">
                                <div className="flex-1">價格問題: {yahooList.numPrice}</div>
                                <div className="flex-1">產品缺失: {yahooList.numMissing}</div>
                                <div className="flex-1">產品重複: {yahooList.numRepeat}</div>
                                <div className="flex-1">圖片缺失: {yahooList.numImage}</div>
                            </div>
                        </div>
                        <div className="flex-1 relative">
                            <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll gap-4">
                                {yahooList.updating ? (
                                    <div className="text-center">更新中...</div>
                                ) : (
                                    <ul className="flex flex-col gap-4">
                                        {yahooList.list.map((watchItem: YahooWatchItemsInterface) => (
                                            <li key={`${watchItem.code}-${watchItem.type}`}>
                                                <div className="w-full">
                                                    <WatchItemYahoo
                                                        {...watchItem}
                                                        getImageCache={yahooList.getImageCache}
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Yahoo;
