import Logo from "../assets/logo.svg";
function Welcome() {
    return (
        <div className="h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8 sm:p-6  bg-gray-800">
            <h1 className="text-2xl font-semibold text-white mb-4 hidden md:block">歡迎！</h1>
            <div className="flex flex-1 flex-col justify-center items-center">
                <img className="h-20 w-auto mb-8" src={Logo} alt="WatchSearch" />
                <div className="max-w-lg text-white text-center">
                    <p>在這裡找到的所有資料都是機密的。</p>
                    <p>該軟件的使用僅限於工作。</p>
                    <p>任何在工作之外使用此軟件的行為都違反了我們的條款和條件。</p>
                    <p>請確保您允許位置權限，否則您的設備和 IP 將在我們的系統中被標記。</p>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
