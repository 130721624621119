import { MapInterface } from "./Interfaces";
const APIURL = "https://qjaerjn9nd.execute-api.ap-northeast-1.amazonaws.com/production";
const StoreList: Array<string> = [
    "EGPS",
    "Radar",
    "Adam",
    "FinalStop",
    "GoldenMorning",
    "GoodTimes",
    "Raku",
    "TimeLife",
    "QwqWatch",
    "WatchArt",
    "DiamondTime",
    "Emc2",
    "GoldenStar",
    "GsmWatch",
    "PlayWatch",
    "DragonTime",
    // "TtWatches",
    "WatchandTime",
    "WatchLife",
    "WatchStore",
    // "SongDragon",
    // "XmWatch"
    "NorthForest",
    "Chilia",
    "Agan",
    "Allen",
    "Lende",
    "Pegasus",
    "Yellow",
    "XrWatch"
];

const StoreListEnglishToChinese: MapInterface = {
    EGPS: "永生",
    Adam: "鴻昇",
    DiamondTime: "鑽時計",
    Emc2: "相對論",
    FinalStop: "鐘點站",
    GoldenMorning: "金宸",
    GoldenStar: "金星",
    GoodTimes: "好時計",
    GsmWatch: "計時門",
    PlayWatch: "玩錶舍",
    QwqWatch: "Q小姐",
    Radar: "雷達站",
    Raku: "川哥",
    TimeLife: "經緯度",
    DragonTime: "龍團名錶",
    // TtWatches: "台北腕錶",
    WatchandTime: "黃忠政",
    WatchArt: "王永昌",
    WatchLife: "玩錶人生",
    WatchStore: "名錶窩",
    XmWatch: "鑫鎂鐘錶",
    // SongDragon: "松龍",
    NorthForest: "北林",
    Chilia: "奇力亞",
    Agan: "阿甘",
    Allen: "Allen",
    Lende: "仁德",
    Pegasus: "飛馬",
    Yellow: "鐘錶 璜",
    XrWatch: "鑫瑞"
};

const StoreListColors: MapInterface = {
    Adam: "6820CE",
    DiamondTime: "75F70F",
    Emc2: "C06B11",
    FinalStop: "A7B92C",
    GoldenMorning: "2A1286",
    GoldenStar: "23FBC2",
    GoodTimes: "FA473A",
    GsmWatch: "798722",
    PlayWatch: "C3089B",
    QwqWatch: "19ABE0",
    Radar: "F4D308",
    Raku: "AF1D19",
    TimeLife: "91CBF1",
    TtWatches: "32A590",
    WatchandTime: "C1ADDB",
    WatchArt: "1FB033",
    WatchLife: "A0DED8",
    WatchStore: "",
    XmWatch: "E58135",
    // SongDragon: "",
    NorthForest: "",
    Chilia: "",
    Agan: "",
    Allen: "",
    Lende: "",
    Pegasus: "",
    Yellow: "",
    XrWatch: ""
};

const TitleToChinese: MapInterface = {
    owner: "老闆",
    manager: "主管",
    associate: "職員",
    accountant: "會計",
    newHire: "新人",
    kevin: "Kevin"
};

export { APIURL, StoreList, StoreListEnglishToChinese, StoreListColors, TitleToChinese };
