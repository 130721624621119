import { SearchIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useState } from "react";
import { WatchItemEgps } from "../components";
import { useWatchListContext } from "../context/WatchList";
import { EgpsWatchItemInterface } from "../Interfaces";
import { debounce } from "lodash";
function Warranty() {
    const { egpsWatches }: { egpsWatches: Array<EgpsWatchItemInterface> } = useWatchListContext();
    const [sortedWatches, setSortedWatches] = useState<Array<EgpsWatchItemInterface>>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const delayedFilterList = useCallback(
        debounce((egpsWatches, searchQuery) => filterList(egpsWatches, searchQuery), 1000),
        []
    );

    const filterList = (egpsWatches: Array<EgpsWatchItemInterface>, searchQuery: string) => {
        if (!egpsWatches) {
            setSortedWatches([]);
        } else if (!searchQuery) {
            setSortedWatches(egpsWatches.slice(0, 50));
        } else {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = egpsWatches.filter((watchItem) => {
                if (
                    watchItem?.brand?.toLowerCase().includes(lowerCaseQuery) ||
                    watchItem?.nameLong?.toLowerCase().includes(lowerCaseQuery) ||
                    watchItem?.nameShort?.toLowerCase().includes(lowerCaseQuery) ||
                    watchItem?.publicID?.toLowerCase().includes(lowerCaseQuery) ||
                    watchItem?.privateID?.toLowerCase().includes(lowerCaseQuery)
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            setSortedWatches(filtered.slice(0, 50));
        }
    };
    useEffect(() => {
        delayedFilterList(egpsWatches, searchQuery);
    }, [egpsWatches, searchQuery]);

    return (
        <div className="h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8 sm:p-6 ">
            <h1 className="text-2xl font-semibold text-gray-900 mb-4">保修單</h1>
            <div className="flex flex-1 flex-col lg:flex-row gap-4">
                <div className="flex flex-col flex-1 gap-4">
                    <div className="w-full p-1 bg-white rounded-md mr-3 sm:mr-0 m-auto shadow-sm border border-gray-300">
                        <div className="w-full flex md:ml-0">
                            <label htmlFor="search-field" className="sr-only">
                                Search
                            </label>
                            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input
                                    id="search-field"
                                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                    placeholder="Search"
                                    type="search"
                                    name="search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 relative">
                        <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll gap-4">
                            <ul className="flex flex-col gap-4">
                                {sortedWatches.map((watchItem) => (
                                    <li key={watchItem.databaseID}>
                                        <button className="w-full">
                                            <WatchItemEgps {...watchItem} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex-1"></div>
            </div>
        </div>
    );
}

export default Warranty;
