import { Link } from "react-router-dom";

const storeStats = [
    {
        store: "Adam",
        new: 26,
        sold: 12,
        priceChanges: 12,
        lastUpdated: "12:24",
        image: ""
    },
    {
        store: "EGPS",
        new: 26,
        sold: 12,
        priceChanges: 12,
        lastUpdated: "12:24",
        image: ""
    }
];
function CompetitorStats() {
    return (
        <div className="flex flex-col flex-1 max-h-full h-full rounded-lg bg-white py-4 pl-4  shadow-sm border border-gray-300">
            <h1 className="text-xl font-semibold text-gray-900">Activity</h1>
            <ul className="divide-y divide-gray-200 overflow-y-scroll pr-4">
                {storeStats.map((storeStat) => (
                    <li key={storeStat.store} className="py-2 hover:bg-slate-100">
                        <Link to="/stats" className="flex space-x-3">
                            <img className="h-6 w-6 rounded-full" src={storeStat.image} alt="" />
                            <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-sm font-semibold">{storeStat.store}</h3>
                                    <p className="text-sm text-gray-500">{storeStat.lastUpdated}</p>
                                </div>
                                <div className="flex flex-row justify-between text-slate-600">
                                    <div className="text-sm font-normal flex-1">
                                        New: <span className="font-bold">{storeStat.new}</span>
                                    </div>
                                    <div className="text-sm font-normal flex-1">
                                        Sold: <span className="font-bold">{storeStat.sold}</span>
                                    </div>
                                    <div className="text-sm font-normal flex-1">
                                        Price Change: <span className="font-bold">{storeStat.priceChanges}</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default CompetitorStats;
