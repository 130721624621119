// This file is for showing WatchItems on the Yahoo/EGPS compare page.
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { YahooWatchItemsInterface, MapInterface } from "../Interfaces";

const storeNameMap: MapInterface = {
    everlong: "永久",
    evergrow: "永生",
    yahoo: "Yahoo"
};

function WatchItemYahoo(props: YahooWatchItemsInterface) {
    const [image, setImage] = useState(props.image);
    const [imageA, setImageA] = useState(props.imageA);
    const [imageB, setImageB] = useState(props.imageB);
    const updateImageToHttps = async (url: string, setter: Dispatch<SetStateAction<string | undefined>>) => {
        const newUrl = await props.getImageCache(url);
        setter(newUrl);
    };
    useEffect(() => {
        if (props.image?.includes("http://")) {
            updateImageToHttps(props.image, setImage);
        }
    }, [props.image]);
    useEffect(() => {
        if (props.imageA?.includes("http://")) {
            updateImageToHttps(props.imageA, setImageA);
        }
    }, [props.imageA]);
    useEffect(() => {
        if (props.imageB?.includes("http://")) {
            updateImageToHttps(props.imageB, setImageB);
        }
    }, [props.imageB]);

    return (
        <div className="w-full aspect-w-3 aspect-h-1 object-contain relative">
            <div className="absolute top-0 right-0 left-0 bottom-0 card flex flex-col overflow-hidden">
                <h1 className="px-4 truncate max-w-full bg-gray-600 text-white">{props.name}</h1>
                {WatchItemDynamicComponent({
                    ...props,
                    image: image || props.image,
                    imageA: imageA || props.imageA,
                    imageB: imageB || props.imageB
                })}
            </div>
        </div>
    );
}

const WatchItemDynamicComponent = (props: YahooWatchItemsInterface) => {
    switch (props.type) {
        case "price":
            return <WatchItemPrice {...props} />;
        case "repeat":
            return <WatchItemRepeat {...props} />;
        case "image":
            return <WatchItemImage {...props} />;
        case "missing":
            return <WatchItemMissing {...props} />;
        default:
            <></>;
    }
};

const WatchItemPrice = (props: YahooWatchItemsInterface) => {
    return (
        <div className="flex flex-1 flex-row">
            <div className="w-1/3 relative flex flex-col">
                {props.imageA ? (
                    <div className="flex-1 relative">
                        <img
                            className="absolute max-h-full w-full  object-cover object-center overflow-hidden"
                            src={props.imageA}
                        />
                    </div>
                ) : (
                    <div className="flex-1 flex justify-center items-center text-gray-600 text-xs bg-gray-100">
                        "No Image"
                    </div>
                )}
                <div className="bg-green-600 h-8 text-white text-center font-bold flex items-center justify-center">
                    價格問題
                </div>
            </div>
            <div className="flex flex-col flex-[2_2_auto]">
                <div className="relative flex-1 p-2 text-left">
                    <div>
                        產品編號: <span className="font-bold">{props.code}</span>
                    </div>
                    <div></div>
                </div>
                <div className="w-full flex flex-row">
                    {[
                        {
                            isYahoo: true,
                            url: props.urlA
                        },
                        {
                            isYahoo: false,
                            url: props.urlB
                        }
                    ].map((store) => (
                        <a
                            key={store.url}
                            href={store.url}
                            target="_blank"
                            className="flex-1 relative h-8 flex items-center justify-center font-medium text-sm text-white bg-gray-600 hover:bg-gray-500"
                        >
                            網址 ( {store.isYahoo && "Yahoo"} {storeNameMap[props.store] || "EGPS"})
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};
const WatchItemRepeat = (props: YahooWatchItemsInterface) => {
    return (
        <div className="flex flex-1 flex-row">
            <div className="w-1/3 relative flex flex-col">
                {props.imageA ? (
                    <div className="flex-1 relative">
                        <img
                            className="absolute max-h-full w-full object-cover object-center overflow-hidden z-10"
                            src={props.imageA}
                        />
                    </div>
                ) : (
                    <div className="flex-1 flex justify-center items-center text-gray-600 text-xs bg-gray-100">
                        "No Image"
                    </div>
                )}
                <div className="bg-blue-600 h-8 text-white text-center font-bold flex items-center justify-center">
                    產品重複
                </div>
            </div>
            <div className="flex flex-col flex-[2_2_auto]">
                <div className="relative flex-1 p-2 text-left">
                    產品編號: <span className="font-bold">{props.code}</span>
                </div>
                <a
                    href={props.url}
                    target="_blank"
                    className="relative h-8 flex items-center justify-center font-medium text-sm text-white bg-gray-600 hover:bg-gray-500"
                >
                    網址 ( {props.platform && storeNameMap[props.platform]} {storeNameMap[props.store] || "EGPS"})
                </a>
            </div>
        </div>
    );
};
const WatchItemImage = (props: YahooWatchItemsInterface) => {
    return (
        <div className="flex flex-1 flex-row">
            <div className="w-1/3 relative flex flex-col">
                {props.image ? (
                    <div className="flex-1 relative">
                        <img
                            className="absolute max-h-full w-full  object-cover object-center overflow-hidden"
                            src={props.image}
                        />
                    </div>
                ) : (
                    <div className="flex-1 flex justify-center items-center text-gray-600 text-xs bg-gray-100">
                        "No Image"
                    </div>
                )}
                <div className="bg-orange-600 h-8 text-white text-center font-bold flex items-center justify-center">
                    圖片缺失
                </div>
            </div>
            <div className="flex flex-col flex-[2_2_auto]">
                <div className="relative flex-1 p-2 text-left">
                    產品編號: <span className="font-bold">{props.code}</span>
                </div>
                <a
                    href={props.url}
                    target="_blank"
                    className="relative h-8 flex items-center justify-center font-medium text-sm text-white bg-gray-600 hover:bg-gray-500"
                >
                    網址 ( {props.platform && storeNameMap[props.platform]} {storeNameMap[props.store] || "EGPS"})
                </a>
            </div>
        </div>
    );
};
const WatchItemMissing = (props: YahooWatchItemsInterface) => {
    return (
        <div className="flex flex-1 flex-row">
            <div className="w-1/3 relative flex flex-col">
                {props.image ? (
                    <div className="flex-1 relative">
                        <img
                            className="absolute max-h-full w-full  object-cover object-center overflow-hidden"
                            src={props.image}
                        />
                    </div>
                ) : (
                    <div className="flex-1 flex justify-center items-center text-gray-600 text-xs bg-gray-100">
                        "No Image"
                    </div>
                )}
                <div className="bg-red-600 h-8 text-white text-center font-bold flex items-center justify-center">
                    產品缺失
                </div>
            </div>
            <div className="flex flex-col flex-[2_2_auto]">
                <div className="relative flex-1 p-2 text-left">
                    產品編號: <span className="font-bold">{props.code}</span>
                </div>
                <a
                    href={props.url}
                    target="_blank"
                    className="relative h-8 flex items-center justify-center font-medium text-sm text-white bg-gray-600 hover:bg-gray-500"
                >
                    網址 ( {props.platform && storeNameMap[props.platform]} {storeNameMap[props.store] || "EGPS"})
                </a>
            </div>
        </div>
    );
};

export default WatchItemYahoo;
