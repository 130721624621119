import { GetResult } from "@fingerprintjs/fingerprintjs";
import { useCallback } from "react";
import { APIURL } from "../constants";
import {
    WatchItemInterface,
    YahooWatchItemsInterface,
    GeolocationInterface,
    EgpsWatchItemInterface,
    UtilData
} from "../Interfaces";
import { DateValueType } from "react-tailwindcss-datepicker";

const useAPI = (apikey: string) => {
    const getWatchLists = useCallback(
        async (storeName: string): Promise<Array<WatchItemInterface>> => {
            const combinedWatchList = [];
            let lastEvaluatedKey = null;
            do {
                try {
                    let url = `${APIURL}/watches?store=${storeName}`;
                    if (lastEvaluatedKey) {
                        url += "&lastEvaluatedKey=" + encodeURIComponent(lastEvaluatedKey);
                    }
                    const response = await fetch(url, {
                        headers: {
                            Authorization: apikey
                        }
                    });
                    const body = await response.json();
                    combinedWatchList.push(...(body?.watchList || []));
                    lastEvaluatedKey = JSON.stringify(body?.lastEvaluatedKey);
                } catch (err) {
                    break;
                }
            } while (lastEvaluatedKey);
            return combinedWatchList;
        },
        [apikey]
    );

    const getYahooLists = useCallback(
        async (storeName: string): Promise<Array<YahooWatchItemsInterface>> => {
            try {
                const response = await fetch(`${APIURL}/yahoo?store=${storeName}`, {
                    headers: {
                        Authorization: apikey
                    }
                });
                const body = await response.json();
                const compareList: Array<YahooWatchItemsInterface> = body || [];
                return compareList;
            } catch (err) {
                return [] as Array<YahooWatchItemsInterface>;
            }
        },
        [apikey]
    );

    // Don't change this to APIKey stupid <3
    const checkToken = async (token: string) => {
        try {
            const response = await fetch(`${APIURL}/ping`, {
                headers: {
                    Authorization: token
                }
            });
            const status = response.status;
            if (status !== 200) {
                return false;
            }
            return true;
        } catch (err) {
            return false;
        }
    };

    const getImageCache = async (url: string) => {
        try {
            const response = await fetch(`${APIURL}/imagecache?target=${url}`, {
                headers: {
                    Authorization: apikey,
                    Accept: "image/webp",
                    "Content-Type": "image/webp",
                    "Content-Encoding": "gzip"
                }
            });
            const status = response.status;
            if (status !== 200) {
                return false;
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            return blobUrl;
        } catch (err) {
            return false;
        }
    };

    const getUser = async () => {
        try {
            const response = await fetch(`${APIURL}/user`, {
                headers: {
                    Authorization: apikey
                }
            });
            const status = response.status;
            if (status !== 200) {
                return false;
            }
            const body = await response.json();
            return body;
        } catch (err) {
            return false;
        }
    };

    const postMetadata = async (
        userIP: string,
        userFingerprint: GetResult,
        userLocation: GeolocationInterface | null,
        pathname: string
    ) => {
        if (location?.hostname === "localhost") {
            return;
        }
        try {
            const response = await fetch(`${APIURL}/metadata`, {
                method: "POST",
                headers: {
                    Authorization: apikey
                },
                body: JSON.stringify({
                    userIP: userIP,
                    userFingerprint: userFingerprint,
                    visitorId: userFingerprint.visitorId,
                    userLocation: userLocation,
                    pathname: pathname
                })
            });
            const status = response.status;
            if (status !== 200) {
                return false;
            }
            const body = await response.json();
            return body;
        } catch (err) {
            return false;
        }
    };

    const getUsersActivity = async (user?: string, dateRange?: DateValueType) => {
        const requestUrlPart = [APIURL, "/activity?"];
        try {
            const hasUser = !!(user && user !== "全部");
            const hasDate = !!(dateRange?.startDate && dateRange?.endDate);

            if (hasUser) {
                requestUrlPart.push("user=" + user);
            }
            if (hasUser && hasDate) {
                requestUrlPart.push("&");
            }
            if (hasDate) {
                requestUrlPart.push(`startDate=${dateRange.startDate?.valueOf()}`);
                requestUrlPart.push("&");
                requestUrlPart.push(`endDate=${dateRange.endDate?.valueOf()}`);
            }
            const response = await fetch(requestUrlPart.join(""), {
                headers: {
                    Authorization: apikey
                }
            });
            const status = response.status;
            if (status !== 200) {
                return false;
            }
            const body = await response.json();
            if (body.error) {
                return [];
            }
            return body;
        } catch (err) {
            return false;
        }
    };

    const getEgpsWatchList = useCallback(async (): Promise<Array<EgpsWatchItemInterface>> => {
        try {
            const response = await fetch(`${APIURL}/egps`, {
                headers: {
                    Authorization: apikey
                }
            });
            const body = await response.json();
            const watchList: Array<EgpsWatchItemInterface> = body?.watchList || [];
            return watchList;
        } catch (err) {
            return [] as Array<EgpsWatchItemInterface>;
        }
    }, [apikey]);

    const getUtilData = useCallback(async (): Promise<UtilData> => {
        try {
            const response = await fetch(`${APIURL}/util`, {
                headers: {
                    Authorization: apikey
                }
            });
            const body = await response.json();
            return body;
        } catch (err) {
            return {} as UtilData;
        }
    }, [apikey]);

    return {
        getWatchLists,
        getEgpsWatchList,
        getYahooLists,
        checkToken,
        getImageCache,
        getUser,
        postMetadata,
        getUsersActivity,
        getUtilData
    };
};

export default useAPI;
