import { Link } from "react-router-dom";

const people = [
    {
        name: "Lindsay Walton",
        imageUrl:
            "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
    }
];
const activityItems = [
    {
        id: 1,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 12,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 14,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 15,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 16,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 17,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 18,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 19,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 0,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    },
    {
        id: 20,
        person: people[0],
        project: "Workcation",
        commit: "2d89f0c8",
        environment: "production",
        time: "1h"
    }
];
function Activity() {
    return (
        <div className="flex flex-col flex-1 max-h-full rounded-lg bg-white py-4 pl-4  shadow-sm border border-gray-300">
            <h1 className="text-xl font-semibold text-gray-900">Activity</h1>
            <ul className="divide-y divide-gray-200 overflow-y-scroll pr-4">
                {activityItems.map((activityItem) => (
                    <li key={activityItem.id} className="py-4 hover:bg-slate-100">
                        <Link to="/search" className="flex space-x-3">
                            <img className="h-6 w-6 rounded-full" src={activityItem.person.imageUrl} alt="" />
                            <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-sm font-medium">{activityItem.person.name}</h3>
                                    <p className="text-sm text-gray-500">{activityItem.time}</p>
                                </div>
                                <p className="text-sm text-gray-500">
                                    Deployed {activityItem.project} ({activityItem.commit} in master) to{" "}
                                    {activityItem.environment}
                                </p>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Activity;
