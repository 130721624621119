// db.ts
import Dexie, { Table } from "dexie";
import {
    EgpsWatchItemInterface,
    WatchItemsListStoreKeyValueInterface,
    YahooWatchItemsStoreKeyValueInterface
} from "./Interfaces";

export class MySubClassedDexie extends Dexie {
    watchList!: Table<WatchItemsListStoreKeyValueInterface>;
    yahooList!: Table<YahooWatchItemsStoreKeyValueInterface>;
    egpsWatchItems!: Table<EgpsWatchItemInterface>;
    constructor() {
        super("WatchSearch");
        this.version(4).stores({
            watchList: "store, watches",
            yahooList: "store, watches",
            egpsWatchItems:
                "privateID, bigImage, brand, CTR, databaseID, ID, lastUpdate, nameLong, nameShort, privatePrice, publicID, publicPrice, smallImage, sold, store, watchID"
        });
    }
}

export const db = new MySubClassedDexie();
