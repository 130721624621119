import { Link } from "react-router-dom";

const EgpsStatus = () => {
    return (
        <div className="flex flex-col h-full rounded-lg bg-white p-4  shadow-sm border border-gray-300">
            <h1 className="flex-none text-xl font-semibold text-gray-900 border-b mb-2">EGPS Status</h1>
            <Link to="/compare" className="flex-1 hover:bg-slate-100">
                <div className="flex flex-row justify-between">
                    <h1 className="flex-none text-m font-semibold text-gray-900 ">EverGrow</h1>
                    <div className="flex-none text-xs font-normal text-gray-600">Last Update: 5/4 23:14</div>
                </div>
                <div></div>
            </Link>
            <Link to="/compare" className="flex-1 hover:bg-slate-100">
                <div className="flex flex-row justify-between">
                    <h1 className="flex-none text-m font-semibold text-gray-900">EverLong</h1>
                    <div className="flex-none text-xs font-normal text-gray-600">Last Update: 5/4 23:14</div>
                </div>
                <div></div>
            </Link>
        </div>
    );
};

export default EgpsStatus;
