import { PlusIcon, SearchIcon } from "@heroicons/react/solid";

function Compare() {
    return (
        <div className="h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8  sm:p-6 ">
            <h1 className="text-2xl font-semibold text-gray-900 mr-2 pb-4">相比</h1>
            <div className="flex gap-2 align-center">
                <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                    <PlusIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                </button>
                <div className="flex-1 p-1 bg-white rounded-md mr-3 sm:mr-0 m-auto shadow-sm border border-gray-300">
                    <div className="w-full flex md:ml-0">
                        <label htmlFor="search-field" className="sr-only">
                            Search
                        </label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                <SearchIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                                id="search-field"
                                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                placeholder="Search"
                                type="search"
                                name="search"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row flex-1">
                <div className="flex-1 relative mt-5">
                    <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll">
                        <div className="gap-4 grid watchItemTemplate">
                            {/* <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem /> */}
                        </div>
                    </div>
                </div>
                <div className="flex-1 relative mt-5">
                    <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll">
                        <div className="gap-4 grid watchItemTemplate">
                            {/* <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem /> */}
                        </div>
                    </div>
                </div>
                <div className="flex-1 relative mt-5">
                    <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll">
                        <div className="gap-4 grid watchItemTemplate">
                            {/* <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem />
                            <WatchItem /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Compare;
