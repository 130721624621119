import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { MapInterface } from "../Interfaces";

function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(" ");
}

function Dropdown({
    selected,
    items,
    itemsDisplayMap,
    setSelected
}: {
    selected: string;
    items: Array<string>;
    itemsDisplayMap?: MapInterface;
    setSelected: (selected: string) => void;
}) {
    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="relative h-full">
                        <Listbox.Button className=" h-full relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <span className="block truncate">{itemsDisplayMap?.[selected] || selected}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {items.map((item: string) => (
                                    <Listbox.Option
                                        key={item}
                                        className={({ active }) =>
                                            classNames(
                                                active ? "text-white bg-indigo-600" : "text-gray-900",
                                                "cursor-default select-none relative py-2 pl-8 pr-4"
                                            )
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={classNames(
                                                        selected ? "font-semibold" : "font-normal",
                                                        "block truncate"
                                                    )}
                                                >
                                                    {itemsDisplayMap?.[item] || item}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? "text-white" : "text-indigo-600",
                                                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}

export default Dropdown;
