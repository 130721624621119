function Profile() {
    return (
        <div>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Profile</h1>
            </div>
        </div>
    );
}

export default Profile;
