import { EgpsWatchItemInterface } from "../Interfaces";

function WatchItemEgps(props: EgpsWatchItemInterface) {
    return (
        <div className="w-full aspect-w-16 aspect-h-9 object-contain relative">
            <div className="absolute top-0 right-0 left-0 bottom-0 card flex flex-col overflow-hidden">
                <h1 className="px-4 truncate max-w-full bg-gray-600 text-white">{props.nameLong || props.nameShort}</h1>
                <div className="flex flex-1 flex-row">
                    <div className="w-1/3 relative">
                        {props.bigImage ? (
                            <img className="h-full w-full object-cover object-center" src={props.bigImage} />
                        ) : (
                            <div className="h-full w-full flex justify-center items-center text-gray-600 text-xs bg-gray-100">
                                "No Image"
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col flex-[2_2_auto] text-left pl-4 pt-4">
                        <div>Brand:{props.brand}</div>
                        <div>Public Price:{props.priceHistory?.[0]?.price || "--"}</div>
                        <div>Private Price:{props.priceHistory?.[0]?.price || "--"}</div>
                        <div>Public ID: {props.publicID}</div>
                        <div>Private ID: {props.privateID}</div>
                        <div>Watch ID: {props.watchID}</div>
                        <div>Sold?: {props.sold}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WatchItemEgps;
