import { Activity, CompetitorStats, EgpsStatus } from "../components";
function Dashboard() {
    return (
        <div className="h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8 sm:p-6 ">
            <h1 className="text-2xl font-semibold text-gray-900 mb-4">控制板</h1>
            <div className="flex-1 relative">
                <div className="grid sm:grid-cols-2 dashboardTemplateRows absolute top-1 left-0 right-0 bottom-0 overflow-y-scroll gap-4 sm:overflow-hidden">
                    <div className="sm:flex sm:flex-col row-start-3 sm:row-start-1 sm:col-start-1 sm:row-span-2 sm:col-span-1">
                        <Activity />
                    </div>
                    <div className="sm:flex-none h-40 row-start-1 sm:row-start-1 sm:col-start-2 sm:row-span-1 sm:col-span-1">
                        <EgpsStatus />
                    </div>
                    <div className="sm:flex-1 relative row-start-2 sm:row-start-2 sm:col-start-2 sm:row-span-1 sm:col-span-1">
                        <div className="sm:absolute top-1 left-0 right-0 bottom-0">
                            <CompetitorStats />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
